export var transactionStatus = {
  Completed: 0,
  Pending: 1,
  Rejected: 2,
  Blocked: 3,
  Master: 4
};
export var transactionStatusString = {
  Completed: 'Completed',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Blocked: 'Blocked',
  Master: 'Master'
};
export var transactionType = {
  Outbound: 0,
  Inbound: 1
};
export var splitRunType = {
  Regular: 'regular',
  Promo: 'promo'
};
export var splitRunStatus = {
  Pending: 'pending',
  Success: 'success',
  Failure: 'failure'
};
export var withdrawalStatus = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Cancelled: 'cancelled'
};
export var excludeMasterStatus = {
  MasterOnly: false,
  NonMaster: true
};