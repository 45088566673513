import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _isFunction from "lodash/isFunction";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import { useState, useEffect, useImperativeHandle } from 'react';
import moment from 'moment';
import validateInput from '../../../utils/inputValidation';
var validateIsRequired = function validateIsRequired(value) {
  return validateInput(value, 'required');
};
export var useFormInput = function useFormInput(_ref) {
  var _inputProps$dateProps;
  var id = _ref.id,
    initValue = _ref.value,
    changeValueInForm = _ref.onChange,
    changeErrorInForm = _ref.onError,
    validate = _ref.validate,
    pattern = _ref.pattern,
    disabled = _ref.disabled,
    readOnly = _ref.readOnly,
    required = _ref.required,
    readOnlyEditable = _ref.readOnlyEditable,
    isTouchedInit = _ref.isTouched,
    inputProps = _ref.inputProps,
    inputRef = _ref.inputRef,
    number = _ref.number;
  var strVal = initValue !== null && initValue !== void 0 && initValue.toString ? initValue.toString() : initValue !== null && initValue !== void 0 ? initValue : '';
  var _useState = useState(strVal),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  var _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    error = _useState4[0],
    setError = _useState4[1];
  var _useState5 = useState(isTouchedInit),
    _useState6 = _slicedToArray(_useState5, 2),
    isTouched = _useState6[0],
    setIsTouched = _useState6[1];
  var isDateTimePicker = inputProps === null || inputProps === void 0 ? void 0 : inputProps.dateTime;
  var _ref2 = (_inputProps$dateProps = inputProps === null || inputProps === void 0 ? void 0 : inputProps.dateProps) !== null && _inputProps$dateProps !== void 0 ? _inputProps$dateProps : {},
    formatDate = _ref2.displayFormat;
  useEffect(function () {
    setValue(strVal);
  }, [strVal]);
  useEffect(function () {
    checkForError(value);
  }, [isTouched]);
  useEffect(function () {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);
  useEffect(function () {
    if (disabled || readOnly) return;
    // Form component holds all values and errors into state so we need to pass the new value and error to the form component
    checkForError(value);
    _isFunction(changeValueInForm) && changeValueInForm(id, number ? Number(value) : value);
  }, [value]);
  useImperativeHandle(inputRef, function () {
    return {
      changeValue: function changeValue(newValue) {
        return onChange({
          target: {
            value: newValue
          }
        });
      },
      checkForError: checkForError,
      setError: handleError,
      value: value,
      changeIsTouched: setIsTouched
    };
  });

  // Handle change of the input,
  // All form inputs are controlled and have onChange
  var onChange = function onChange(event) {
    var newValue = event.target.value;
    if (newValue !== null && newValue !== void 0 && newValue.length && _isFunction(pattern) && !pattern(newValue)) return;
    setValue(isDateTimePicker ? moment(newValue).format(formatDate !== null && formatDate !== void 0 ? formatDate : 'DD MMM YYYY') : newValue);
  };

  // On blur is used only to change the flag for touched
  var onBlur = function onBlur() {
    !isTouched && setIsTouched(true);
    checkForError(value);
  };
  var checkForError = function checkForError(newValue) {
    var newError = null;

    // If validate function is provided check for error
    if (_isFunction(validate)) newError = validate(newValue, id);else if (required) newError = validateIsRequired(newValue);
    handleError(newError);
  };
  var handleError = function handleError(newError) {
    // The first time when you edit the field it will not generate error for the field
    // but it will pass the error to the form so you can not submit it until there is error
    isTouched && setError(newError);
    // The form component holds all errors into ref so we need to pass the new error into the form component
    _isFunction(changeErrorInForm) && changeErrorInForm(id, newError);
  };
  return {
    // Input props
    value: value,
    onChange: onChange,
    onBlur: onBlur,
    disabled: readOnly || readOnlyEditable || disabled,
    // Form related props
    error: error,
    isTouched: isTouched
  };
};