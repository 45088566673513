function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _orderBy from "lodash/orderBy";
import _isBoolean from "lodash/isBoolean";
import _pick from "lodash/pick";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import queryString from 'query-string';
import { type } from '../../enums/wallets';
import { transactionStatus, withdrawalStatus } from '../../enums/transactions';
import { endOfTheDay, startOfTheDay, validDate } from '../../utils';
export var addSubtractBalanceBody = function addSubtractBalanceBody(data) {
  var _data$balance, _data$blockedBalance, _data$reason;
  return {
    balance: (_data$balance = data === null || data === void 0 ? void 0 : data.balance) !== null && _data$balance !== void 0 ? _data$balance : 0,
    blockedBalance: (_data$blockedBalance = data === null || data === void 0 ? void 0 : data.blockedBalance) !== null && _data$blockedBalance !== void 0 ? _data$blockedBalance : 0,
    reason: (_data$reason = data === null || data === void 0 ? void 0 : data.reason) !== null && _data$reason !== void 0 ? _data$reason : ''
  };
};
export var getWalletModel = function getWalletModel(response) {
  var _response$wallets;
  return response === null || response === void 0 ? void 0 : (_response$wallets = response.wallets) === null || _response$wallets === void 0 ? void 0 : _response$wallets.map(function (item) {
    var _item$walletType, _item$transactions, _item$transactions2;
    return ((_item$walletType = item.walletType) === null || _item$walletType === void 0 ? void 0 : _item$walletType.toLowerCase()) === type.TOKEN ? _objectSpread(_objectSpread(_objectSpread({}, item), response.splitsBalances), {}, {
      transactions: (_item$transactions = item.transactions) === null || _item$transactions === void 0 ? void 0 : _item$transactions.map(function (el) {
        return _objectSpread(_objectSpread({}, el), {}, {
          completionStatus: transactionStatus[el.completionStatus]
        });
      })
    }) : _objectSpread(_objectSpread({}, item), {}, {
      transactions: (_item$transactions2 = item.transactions) === null || _item$transactions2 === void 0 ? void 0 : _item$transactions2.map(function (el) {
        return _objectSpread(_objectSpread({}, el), {}, {
          completionStatus: transactionStatus[el.completionStatus]
        });
      })
    });
  });
};
export var getAllDepositsParams = function getAllDepositsParams() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultSort = {
    sortField: 'id',
    sortDirection: 'desc'
  };
  var startDate = options.startDate,
    endDate = options.endDate,
    isPaid = options.isPaid,
    sort = options.sort,
    accountId = options.accountId,
    type = options.type;
  var startEndDate = {};
  if (startDate) startEndDate.MatureDateFrom = startOfTheDay(startDate).format();
  if (endDate) startEndDate.MatureDateTo = endOfTheDay(endDate).format();
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), sort !== null && sort !== void 0 ? sort : defaultSort), _isBoolean(isPaid) && {
    isPaid: isPaid
  }), startEndDate), accountId && {
    accountId: accountId
  }), type && {
    type: type
  }), {
    arrayFormat: 'index'
  });
};
export var getSplitRunHistoryModel = function getSplitRunHistoryModel(response) {
  return response && {
    splitRunHistory: _orderBy(response.content, 'id', 'desc'),
    total: response.content.length
  };
};
export var getAllSplitBalanceHistoryParams = function getAllSplitBalanceHistoryParams(options) {
  var accountId = options.accountId,
    nickName = options.nickName,
    splitsRunId = options.splitsRunId;
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), accountId && {
    accountId: accountId
  }), (nickName === null || nickName === void 0 ? void 0 : nickName.length) && {
    nickName: nickName
  }), splitsRunId && {
    splitsRunId: splitsRunId
  }));
};
export var getWithdrawalsModel = function getWithdrawalsModel(response) {
  return response && _objectSpread(_objectSpread({}, response), {}, {
    modifiedOn: validDate(response.modifiedOn),
    createdOn: validDate(response.createdOn),
    executedOnDate: validDate(response.executedOnDate)
  });
};
export var getAllWithdrawalsParams = function getAllWithdrawalsParams(options) {
  var _statusOptionsValues;
  var createdStartDate = options.createdStartDate,
    createdEndDate = options.createdEndDate,
    confirmationStartDate = options.confirmationStartDate,
    confirmationEndDate = options.confirmationEndDate,
    amountFrom = options.amountFrom,
    amountTo = options.amountTo,
    status = options.status,
    searchBy = options.searchBy,
    searchFor = options.searchFor,
    withdrawalMethodType = options.withdrawalMethodType,
    _options$sort = options.sort,
    sort = _options$sort === void 0 ? {
      sortField: 'id',
      sortValue: 'desc'
    } : _options$sort,
    profileFirstName = options.profileFirstName,
    profileLastName = options.profileLastName;
  var filterField = [];
  var filterValue = [];
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortValue: sort.sortValue
    };
  };
  var statusOptionsValues = (_statusOptionsValues = {}, _defineProperty(_statusOptionsValues, withdrawalStatus.Pending, 'pending'), _defineProperty(_statusOptionsValues, withdrawalStatus.Approved, 'approved'), _defineProperty(_statusOptionsValues, withdrawalStatus.Rejected, 'rejected'), _defineProperty(_statusOptionsValues, withdrawalStatus.Cancelled, 'cancelled'), _statusOptionsValues);
  if (createdStartDate) {
    filterField.push('createdFrom');
    filterValue.push(endOfTheDay(createdStartDate).format());
  }
  if (createdEndDate) {
    filterField.push('createdTo');
    filterValue.push(endOfTheDay(createdEndDate).format());
  }
  if (confirmationStartDate) {
    filterField.push('executedFrom');
    filterValue.push(endOfTheDay(confirmationStartDate).format());
  }
  if (confirmationEndDate) {
    filterField.push('executedTo');
    filterValue.push(endOfTheDay(confirmationEndDate).format());
  }
  if (status) {
    filterField.push('status');
    filterValue.push(statusOptionsValues[status]);
  }
  if (searchBy && searchFor !== null && searchFor !== void 0 && searchFor.length) {
    filterField.push(options.searchBy);
    filterValue.push(options.searchFor);
  }
  if (withdrawalMethodType) {
    filterField.push('withdrawalMethodType');
    filterValue.push(withdrawalMethodType);
  }
  if (profileFirstName !== null && profileFirstName !== void 0 && profileFirstName.length) {
    filterField.push('profileFirstName');
    filterValue.push(profileFirstName);
  }
  if (profileLastName !== null && profileLastName !== void 0 && profileLastName.length) {
    filterField.push('profileLastName');
    filterValue.push(profileLastName);
  }
  if (amountFrom && amountFrom >= 0) {
    filterField.push('amountFrom');
    filterValue.push(amountFrom);
  }
  if (amountTo && amountTo >= 0) {
    filterField.push('amountTo');
    filterValue.push(amountTo);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var getDepositModel = function getDepositModel(response) {
  return response && _objectSpread(_objectSpread({}, response), {}, {
    maturityDate: validDate(response.maturityDate),
    createdOn: validDate(response.createdOn),
    modifiedOn: validDate(response.modifiedOn),
    paidOn: validDate(response.paidOn)
  });
};