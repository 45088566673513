export var type = {
  ONE: 'coin',
  COINLEGACY: 'coinlegacy',
  OESP: 'oesp',
  COINSAFE: 'coinsafe',
  CASHSAFE: 'cashsafe',
  CASH: 'cash',
  TRADING: 'trading',
  TOKEN: 'token',
  GAS: 'gas',
  RESERVECASH: 'reservecash',
  BV: 'businessVolume'
};
export var currencyType = {
  EURO: '€',
  EURO_LETTERS: 'EUR',
  TKN: 'TKN',
  ONE: 'ONE',
  OESP: 'OESP',
  BVP: 'BVP'
};
export var withdrawalMethodType = {
  BTC: 'btc',
  USDT: 'usdt'
};
export var bulkSendAmountType = {
  TRANSFERBYACCOUNTIDS: 'transferByAccountIds',
  TRANSFERBYWALLETSIDS: 'transferByWalletIds',
  ADDSUBTRACTBYACCOUNTIDS: 'addSubtractByAccountIds',
  TRANSFERBYACCOUNTNICKNAMES: 'transferByAccountNicknames',
  ADDSUBTRACTBYACCOUNTNICKNAMES: 'addSubtractByAccountNicknames'
};