import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Col, Skeleton } from '../../UI';
import { invoiceLinkButton, marginRight } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Invoice = function Invoice(props) {
  var invoiceData = props.invoiceData,
    orderId = props.orderId;
  var theme = useTheme();
  return ___EmotionJSX("div", null, !_isNil(invoiceData) ? invoiceData !== null && invoiceData !== void 0 && invoiceData.length ? invoiceData === null || invoiceData === void 0 ? void 0 : invoiceData.map(function (el) {
    return ___EmotionJSX("div", {
      key: el.id
    }, ___EmotionJSX("a", {
      href: el.url,
      rel: "noreferrer",
      target: "_blank",
      className: invoiceLinkButton(theme)
    }, "Open Invoice #", el.id));
  }) : ___EmotionJSX("p", null, "There are no Invoice for order with id ", orderId) : ___EmotionJSX(Col, {
    md: 3
  }, ___EmotionJSX(Skeleton, {
    width: 130,
    height: 18,
    className: marginRight(12)
  })));
};
Invoice.propTypes = {
  invoiceData: PropTypes.array,
  orderId: PropTypes.string
};
export default Invoice;