import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _mapOrderPaymentStatu, _mapInvoiceStatus, _mapOrderStatus;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { orderStatus, invoiceStatus, orderPaymentStatus } from '../../../enums/orders';
export var mapOrderPaymentStatus = (_mapOrderPaymentStatu = {}, _defineProperty(_mapOrderPaymentStatu, orderPaymentStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapOrderPaymentStatu, orderPaymentStatus.Confirmed, {
  text: 'Confirmed',
  color: 'success'
}), _defineProperty(_mapOrderPaymentStatu, orderPaymentStatus.Denied, {
  text: 'Denied',
  color: 'error'
}), _mapOrderPaymentStatu);
export var mapInvoiceStatus = (_mapInvoiceStatus = {}, _defineProperty(_mapInvoiceStatus, invoiceStatus.Draft, {
  text: 'Draft',
  color: 'info'
}), _defineProperty(_mapInvoiceStatus, invoiceStatus.Posted, {
  text: 'Posted',
  color: 'success'
}), _defineProperty(_mapInvoiceStatus, invoiceStatus.Cancelled, {
  text: 'Cancelled',
  color: 'error'
}), _mapInvoiceStatus);
export var mapOrderStatus = (_mapOrderStatus = {}, _defineProperty(_mapOrderStatus, orderStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapOrderStatus, orderStatus.Paid, {
  text: 'Completed',
  color: 'success'
}), _defineProperty(_mapOrderStatus, orderStatus.Rejected, {
  text: 'Rejected',
  color: 'error'
}), _mapOrderStatus);
export var orderStatusAdditionalInfo = [{
  label: 'Date and time of completion',
  value: 'modifiedOn',
  dateTime: true
}];
export var orderGeneralInfo = [{
  label: 'Date and time of order',
  value: 'createdOn',
  dateTime: true
}, {
  label: 'Order id',
  value: 'id'
}, {
  label: 'Payment type',
  value: 'paymentTypeId'
}, {
  label: 'Total amount',
  value: 'totalAmount'
}];