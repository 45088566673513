import "core-js/modules/es.string.small.js";
import { css } from 'emotion';
export var datePickerContainer = function datePickerContainer(_ref, theme) {
  var label = _ref.label,
    _ref$showClear = _ref.showClear,
    showClear = _ref$showClear === void 0 ? true : _ref$showClear,
    _ref$small = _ref.small,
    small = _ref$small === void 0 ? true : _ref$small,
    width = _ref.width,
    single = _ref.single;
  return css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .clearIcon': {
      position: 'absolute',
      zIndex: '999999',
      right: -30,
      bottom: 0,
      border: "1px solid ".concat(theme.border),
      borderTopRightRadius: 17,
      borderBottomRightRadius: 17,
      borderLeft: 'none',
      display: 'flex',
      padding: small ? '5px 5px 5px 20px' : '11px 11px 11px 40px'
    }
  }, single && {
    marginRight: 12,
    '& .SingleDatePickerInput': {
      padding: 5,
      borderRadius: '0px !important',
      width: '100%'
    },
    '& .clearIcon': {
      padding: '10px 10px 10px 40px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      right: -40,
      backgroundColor: theme.white
    }
  }, label && {
    marginBottom: 7
  }, showClear && {
    marginRight: single ? 52 : 32
  }, width && {
    width: width
  });
};
export var labelStyles = css({
  paddingLeft: 8,
  fontWeight: 600,
  fontSize: 12
});