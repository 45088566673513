import { css } from 'emotion';
export var invoiceLinkButton = function invoiceLinkButton(theme) {
  return css({
    color: theme.gray,
    border: "1px solid ".concat(theme.grayLightest),
    backgroundColor: 'transparent',
    borderRadius: 4,
    cursor: 'pointer',
    fontSize: 12,
    padding: '6px 12px',
    lineHeight: 1,
    textAlign: 'center',
    '&:hover': {
      border: "1px solid ".concat(theme.grayLight),
      backgroundColor: 'transparent'
    },
    '&:focus, &:active': {
      color: theme.grayLight,
      border: "1px solid ".concat(theme.grayLighter),
      backgroundColor: 'transparent'
    }
  });
};
export var marginRight = function marginRight(val) {
  return css({
    marginRight: val
  });
};