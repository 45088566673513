import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _walletTypeView;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Paper, Icon, Button } from '../../UI';
import GroupOfButtons from '../GroupOfButtons';
import { type as walletType } from '../../../enums/wallets';
import { walletContainer, headerWallet, walletIcon, balanceContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var walletTypeView = (_walletTypeView = {}, _defineProperty(_walletTypeView, walletType.CASH, {
  title: 'Cash Wallet',
  icon: 'fas fa-euro-sign'
}), _defineProperty(_walletTypeView, walletType.RESERVECASH, {
  title: 'Reserve Cash Wallet',
  icon: 'fas fa-euro-sign'
}), _defineProperty(_walletTypeView, walletType.TRADING, {
  title: 'Trading Wallet',
  icon: 'fas fa-exchange-alt'
}), _defineProperty(_walletTypeView, walletType.ONE, {
  title: 'ONE Wallet',
  icon: 'fas fa-coins'
}), _defineProperty(_walletTypeView, walletType.COINLEGACY, {
  title: 'ONE (deprecated) Wallet',
  icon: 'fas fa-coins'
}), _defineProperty(_walletTypeView, walletType.TOKEN, {
  title: 'Token Wallet',
  icon: 'fab fa-gg'
}), _defineProperty(_walletTypeView, walletType.GAS, {
  title: 'Gas Wallet',
  icon: 'fab fa-gripfire'
}), _defineProperty(_walletTypeView, walletType.OESP, {
  title: 'OESP Wallet',
  icon: 'fas fa-coins'
}), _walletTypeView);
var HeaderWallet = function HeaderWallet(props) {
  var _walletTypeView$walle, _walletTypeView$walle2, _wallet$walletType, _wallet$availableSpli;
  var wallet = props.wallet,
    onChange = props.onChange,
    userId = props.userId;
  var theme = useTheme();
  return ___EmotionJSX(Paper, {
    className: walletContainer(theme),
    noPadding: true
  }, ___EmotionJSX("div", {
    className: headerWallet(theme)
  }, ___EmotionJSX("div", null, ___EmotionJSX(Icon, {
    iconName: (_walletTypeView$walle = walletTypeView[wallet.walletType.toLowerCase()]) === null || _walletTypeView$walle === void 0 ? void 0 : _walletTypeView$walle.icon,
    className: walletIcon(theme)
  }), ___EmotionJSX("h3", null, (_walletTypeView$walle2 = walletTypeView[wallet.walletType.toLowerCase()]) === null || _walletTypeView$walle2 === void 0 ? void 0 : _walletTypeView$walle2.title), wallet.id && wallet.walletType.toLowerCase() !== walletType.COINLEGACY && ___EmotionJSX(GroupOfButtons, {
    wallet: wallet,
    onChange: onChange
  })), ___EmotionJSX("div", {
    className: balanceContainer(theme)
  }, ___EmotionJSX("h4", null, wallet.balance === undefined ? 'Balance: N/A' : "Balance: ".concat(wallet.balance)), ___EmotionJSX("h4", null, wallet.blockedBalance === undefined ? 'Blocked Balance: N/A' : "Blocked Balance: ".concat(wallet.blockedBalance)), ((_wallet$walletType = wallet.walletType) === null || _wallet$walletType === void 0 ? void 0 : _wallet$walletType.toLowerCase()) === walletType.TOKEN && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h4", null, wallet.maxSplits ? "Splits: ".concat(wallet.availableSplits, " (").concat(wallet.maxSplits, ")") : "Splits: ".concat((_wallet$availableSpli = wallet.availableSplits) !== null && _wallet$availableSpli !== void 0 ? _wallet$availableSpli : 0, " ")), ___EmotionJSX(Button, {
    linkTo: "/profiles/profile/".concat(userId, "/accounts/").concat(wallet.accountId, "/split-balance-history"),
    small: true,
    outlineInverse: true
  }, "Split History")))));
};
HeaderWallet.propTypes = {
  wallet: PropTypes.object,
  onChange: PropTypes.func,
  userId: PropTypes.string
};
export default HeaderWallet;