export var leadershipRunType = {
  Regular: 'regular',
  Preliminary: 'preliminary'
};
export var leadershipRunStatus = {
  Pending: 'Pending',
  Succeeded: 'Succeeded',
  RanksSaved: 'RanksSaved'
};
export var eagleStatus = {
  Pending: 'pending',
  Succeeded: 'succeeded',
  RanksSaved: 'ranksSaved',
  WalletsRewarded: 'walletsRewarded'
};