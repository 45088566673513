export var orderStatus = {
  Pending: 'pending',
  Paid: 'paid',
  Rejected: 'rejected'
};
export var orderPaymentStatus = {
  Pending: 'pending',
  Confirmed: 'confirmed',
  Denied: 'denied'
};
export var orderPaymentLabels = {
  BankDetails: 'Bank',
  CashWallet: 'Cash',
  ReserveCashWallet: 'Reserve Cash',
  CoinWallet: 'Coin',
  System: 'System',
  PerfectMoney: 'Perfect Money',
  ChinaUnionPay: 'China Union Pay'
};
export var paymentType = {
  BankDetails: 'bankDetails',
  PerfectMoney: 'perfectMoney',
  ChinaUnionPay: 'chinaUnionPay',
  CashWallet: 'cashWallet',
  Bank50Coins50: 'bank50Coins50',
  Cash75Bank25: 'cash75Bank25',
  Bank75Cash25: 'bank75Cash25',
  Bank75ReserveCash25: 'bank75ReserveCash25',
  Bank75Coins25: 'bank75Coins25',
  System: 'system',
  Cash75ReserveCash25: 'cash75ReserveCash25',
  Cash75Coin25: 'cash75Coin25',
  Bank50ReserveCash50: 'bank50ReserveCash50',
  Cash50ReserveCash50: 'cash50ReserveCash50',
  Cash50Coins50: 'cash50Coins50'
};
export var invoiceStatus = {
  Posted: 'posted',
  Draft: 'draft',
  Cancelled: 'cancel'
};
export var orderItemsType = {
  Package: 'package',
  Subscription: 'subscription'
};
export var packageTypes = {
  GasPackage: 'GasPackage',
  Package: 'Package'
};