import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
var _mapTransactionStatus, _mapTransactionStatus2, _mapRunType, _mapSplitStatus, _mapWithdrawalStatus;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { Image } from '../../../components/UI';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import ColorBatch from '../../../components/Shared/ColorBatch';
import TransactionHash from '../../../components/Transactions/TransactionHash';
import ColumnProfileInformation from '../../../components/Transactions/ColumnProfileInformation';
import TransactionDescriptions from '../../../components/Transactions/TransactionsDescriptions';
import Amount from '../../../components/Shared/Amount';
import { transactionStatusString, transactionStatus, splitRunStatus, splitRunType, withdrawalStatus } from '../../../enums/transactions';
import { type } from '../../../enums/wallets';
import { currencyByType, jsonParse, copyToClipboard } from '../../../utils';
import dots from '../../../assets/images/dots.svg';
import { transactionIdStyle, dateAndTimeStyles } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var mapTransactionStatusString = (_mapTransactionStatus = {}, _defineProperty(_mapTransactionStatus, transactionStatusString.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapTransactionStatus, transactionStatusString.Completed, {
  text: 'Completed',
  color: 'success'
}), _defineProperty(_mapTransactionStatus, transactionStatusString.Rejected, {
  text: 'Rejected',
  color: 'error'
}), _defineProperty(_mapTransactionStatus, transactionStatusString.Blocked, {
  text: 'Blocked',
  color: 'warning'
}), _mapTransactionStatus);
export var mapTransactionStatus = (_mapTransactionStatus2 = {}, _defineProperty(_mapTransactionStatus2, transactionStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapTransactionStatus2, transactionStatus.Completed, {
  text: 'Completed',
  color: 'success'
}), _defineProperty(_mapTransactionStatus2, transactionStatus.Rejected, {
  text: 'Rejected',
  color: 'error'
}), _defineProperty(_mapTransactionStatus2, transactionStatus.Blocked, {
  text: 'Blocked',
  color: 'warning'
}), _mapTransactionStatus2);
export var mapRunType = (_mapRunType = {}, _defineProperty(_mapRunType, splitRunType.Promo, {
  text: 'Promo',
  color: 'success'
}), _defineProperty(_mapRunType, splitRunType.Regular, {
  text: 'Regular',
  color: 'info'
}), _mapRunType);
export var mapSplitStatus = (_mapSplitStatus = {}, _defineProperty(_mapSplitStatus, splitRunStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapSplitStatus, splitRunStatus.Success, {
  text: 'Completed',
  color: 'success'
}), _defineProperty(_mapSplitStatus, splitRunStatus.Failure, {
  text: 'Rejected',
  color: 'error'
}), _mapSplitStatus);
export var mapWithdrawalStatus = (_mapWithdrawalStatus = {}, _defineProperty(_mapWithdrawalStatus, withdrawalStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapWithdrawalStatus, withdrawalStatus.Approved, {
  text: 'Approved',
  color: 'success'
}), _defineProperty(_mapWithdrawalStatus, withdrawalStatus.Rejected, {
  text: 'Rejected',
  color: 'error'
}), _defineProperty(_mapWithdrawalStatus, withdrawalStatus.Cancelled, {
  text: 'Cancelled',
  color: 'warning'
}), _mapWithdrawalStatus);
export var transactionsColumns = function transactionsColumns(theme) {
  return [{
    name: 'Transaction ID & Date',
    render: function render(row) {
      return ___EmotionJSX("div", null, ___EmotionJSX("p", {
        className: transactionIdStyle(theme)
      }, row.id), ___EmotionJSX("p", {
        className: dateAndTimeStyles(theme)
      }, moment(row.createdOn).format('DD/MM/YYYY, HH:mm:ss')));
    },
    width: 150
  }, {
    name: 'Sender',
    render: function render(row) {
      return ___EmotionJSX(ColumnProfileInformation, {
        data: row.sender
      });
    },
    width: 240
  }, {
    name: 'Receiver',
    render: function render(row) {
      return ___EmotionJSX(ColumnProfileInformation, {
        data: row.receiver
      });
    },
    width: 240
  }, {
    name: 'Description',
    render: function render(row) {
      return ___EmotionJSX(TransactionDescriptions, {
        description: row.description,
        reason: row.reason,
        userReason: row.userReason,
        externalTopupHash: row.externalTopupHash
      });
    },
    width: 280
  }, {
    name: 'Hash',
    render: function render(_ref, NullItem) {
      var transactionHash = _ref.transactionHash;
      return !_isNil(transactionHash) ? ___EmotionJSX(TransactionHash, {
        transactionHash: transactionHash,
        handleCopyHash: function handleCopyHash() {
          return copyToClipboard(transactionHash, 'Successfully copied transaction hash.');
        }
      }) : ___EmotionJSX(NullItem, null);
    },
    width: 160
  }, {
    name: 'Type',
    render: function render(row) {
      return ___EmotionJSX("p", null, row.type === 'Inbound' ? 'Credit' : 'Debit');
    },
    width: 80
  }, {
    name: 'Status ',
    render: function render(row) {
      var _mapTransactionStatus3, _mapTransactionStatus4;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapTransactionStatus3 = mapTransactionStatus[row.completionStatus]) === null || _mapTransactionStatus3 === void 0 ? void 0 : _mapTransactionStatus3.color
      }, (_mapTransactionStatus4 = mapTransactionStatus[row.completionStatus]) === null || _mapTransactionStatus4 === void 0 ? void 0 : _mapTransactionStatus4.text);
    },
    width: 110
  }, {
    name: 'Amount & Wallet',
    render: function render(row) {
      return ___EmotionJSX(Amount, {
        amount: row.amount,
        currency: row.walletType.toLowerCase(),
        transactionType: row.type,
        walletType: row.walletType.toLowerCase(),
        onTransactionList: true
      });
    },
    width: 140
  }, {
    name: 'Details',
    render: function render(row) {
      return ___EmotionJSX(DetailsLink, {
        row: row,
        url: "/transactions/transaction/".concat(row.id),
        title: ___EmotionJSX(Image, {
          src: dots,
          width: 50,
          height: 50
        })
      });
    },
    width: 80
  }];
};
export var orderStatusAdditionalInfo = [{
  label: 'Date and time of completion',
  value: 'modifiedOn',
  dateTime: true
}];
export var orderGeneralInfo = [{
  label: 'Date and time of order',
  value: 'createdOn',
  dateTime: true
}, {
  label: 'Transaction id',
  value: 'id'
}, {
  label: 'Exchange Transaction id',
  value: 'exchangeTransactionId'
}, {
  label: 'Type',
  value: 'type'
}, {
  label: 'Wallet Type',
  value: 'walletType'
}];
export var breadcrumbsDetailPages = [{
  url: '/transactions',
  label: 'Transactions'
}, {
  label: 'Detail Page'
}];
export var splitRunsColumns = [{
  name: 'Date From',
  value: 'targetDateFrom',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Date To',
  value: 'targetDateTo',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Run ID',
  value: 'id'
}, {
  name: 'Run Type ',
  render: function render(row) {
    var _mapRunType$row$runTy, _mapRunType$row$runTy2;
    return ___EmotionJSX(ColorBatch, {
      type: (_mapRunType$row$runTy = mapRunType[row.runType]) === null || _mapRunType$row$runTy === void 0 ? void 0 : _mapRunType$row$runTy.color
    }, (_mapRunType$row$runTy2 = mapRunType[row.runType]) === null || _mapRunType$row$runTy2 === void 0 ? void 0 : _mapRunType$row$runTy2.text);
  }
}, {
  name: 'Status ',
  render: function render(row) {
    var _mapSplitStatus$row$s, _mapSplitStatus$row$s2;
    return ___EmotionJSX(ColorBatch, {
      type: (_mapSplitStatus$row$s = mapSplitStatus[row.status]) === null || _mapSplitStatus$row$s === void 0 ? void 0 : _mapSplitStatus$row$s.color
    }, (_mapSplitStatus$row$s2 = mapSplitStatus[row.status]) === null || _mapSplitStatus$row$s2 === void 0 ? void 0 : _mapSplitStatus$row$s2.text);
  }
}, {
  name: 'View details ',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/transactions/split-run-history/".concat(row.id)
    });
  }
}];
export var splitRunHistoryAccountsBreadCrumb = [{
  url: '/transactions/split-run-history',
  label: 'Split Runs'
}, {
  label: 'Split Run Details'
}];
export var splitRunHistoryColumns = [{
  name: 'Account Id',
  value: 'accountId'
}, {
  name: 'NickName',
  value: 'nickName'
}, {
  name: 'Before run Available Splits',
  value: 'preRunAvailableSplits'
}, {
  name: 'After run Available Splits',
  value: 'postRunAvailableSplits'
}, {
  name: 'Before run Max Splits',
  value: 'preRunMaxSplits'
}, {
  name: 'After run Max Splits',
  value: 'postRunMaxSplits'
}, {
  name: 'Before run Immediate Mining Balance',
  value: 'preRunImmediateMiningBalance'
}, {
  name: 'After run Immediate Mining Balance',
  value: 'postRunImmediateMiningBalance'
}, {
  name: 'Before run Used Immediate Mining Balance',
  value: 'preRunUsedImmediateMiningBalance'
}, {
  name: 'After run Used Immediate Mining Balance',
  value: 'postRunUsedImmediateMiningBalance'
}, {
  name: 'Before run Token Wallet Balance',
  value: 'preRunTokenWalletBalance'
}, {
  name: 'After run Token Wallet Balance',
  value: 'postRunTokenWalletBalance'
}];
export var withdrawalColumns = [{
  name: 'Created Date',
  value: 'createdOn',
  dateTimeFormat: 'DD/MM/YYYY, HH:mm:ss',
  width: 90
}, {
  name: 'Confirmation Date',
  value: 'executedOnDate',
  dateTimeFormat: 'DD/MM/YYYY, HH:mm:ss',
  width: 100
}, {
  name: 'Amount and Fee',
  render: function render(row) {
    return ___EmotionJSX("div", null, ___EmotionJSX("p", null, "Amount: ", ___EmotionJSX("b", null, currencyByType(row.amount, type.CASH))), ___EmotionJSX("p", null, "Fee: ", ___EmotionJSX("b", null, currencyByType(row.fee, type.CASH))));
  },
  width: 130
}, {
  name: 'User',
  render: function render(row) {
    return ___EmotionJSX("div", null, (row.firstName || row.lastName) && ___EmotionJSX("p", null, "Full name: ", "".concat(row.firstName, " ").concat(row.lastName)), row.nickName && ___EmotionJSX("p", null, "Nickname: ", row.nickName), row.userName && ___EmotionJSX("p", null, "Username: ", row.userName), row.profileId && ___EmotionJSX("p", null, "Profile ID: ", row.profileId), row.accountId && ___EmotionJSX("p", null, "Account ID: ", row.accountId), row.email && ___EmotionJSX("p", null, "Email: ", row.email), row.phone && ___EmotionJSX("p", null, "Phone: ", row.phone));
  }
}, {
  name: 'Status ',
  render: function render(row) {
    var _mapWithdrawalStatus$, _mapWithdrawalStatus$2;
    return ___EmotionJSX(ColorBatch, {
      type: (_mapWithdrawalStatus$ = mapWithdrawalStatus[row.status]) === null || _mapWithdrawalStatus$ === void 0 ? void 0 : _mapWithdrawalStatus$.color
    }, (_mapWithdrawalStatus$2 = mapWithdrawalStatus[row.status]) === null || _mapWithdrawalStatus$2 === void 0 ? void 0 : _mapWithdrawalStatus$2.text);
  },
  width: 100
}, {
  name: 'Type',
  value: 'type',
  width: 80
}, {
  name: 'View details ',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/transactions/withdrawal/".concat(row.id)
    });
  },
  width: 100
}];
export var detailWithdrawalBreadCrumb = [{
  url: '/transactions/withdrawal',
  label: 'Withdrawal'
}, {
  label: 'Detail Page'
}];
export var withdrawalGeneralInfo = function withdrawalGeneralInfo(isPending) {
  return [{
    label: 'Withdrawal ID',
    value: 'id'
  }, {
    label: 'Created Date',
    value: 'createdOn',
    dateTime: true
  }, {
    label: 'Amount',
    value: 'amount',
    currency: type.CASH
  }, {
    label: 'Fee',
    value: 'fee',
    currency: type.CASH
  }, {
    label: 'Payment Type',
    value: 'type'
  }, {
    label: 'Wallet Address',
    render: function render(_ref2) {
      var _jsonParse;
      var value = _ref2.value;
      return (_jsonParse = jsonParse(value)) === null || _jsonParse === void 0 ? void 0 : _jsonParse.addressNumber;
    }
  }, !isPending && {
    label: 'Confirmation Date',
    value: 'executedOnDate',
    dateTime: true
  }, !isPending && {
    label: 'Comment',
    value: 'comment'
  }].filter(Boolean);
};